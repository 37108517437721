/* eslint-disable import/first */
typeof window !== 'undefined' && require('intersection-observer');

import React from 'react';
import { LazyImage } from 'react-lazy-images';
import { Grid, Row, Col } from 'react-flexbox-grid';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import { KEYWORDS } from '../constants/Keywords';
import { PORTFOLIO_PATH } from '../constants/Routes';
import Loader from '../images/Loader.svg';
import Photo1 from '../images/homepage/1.jpg';
import Photo2 from '../images/homepage/2.jpg';
import Photo3 from '../images/homepage/3.jpg';
import Photo4 from '../images/homepage/4.jpg';
import Photo5 from '../images/homepage/5.jpg';
import Photo6 from '../images/homepage/6.jpg';
import { HomepageItem } from '../styles/base';

const IMAGES = [
  { photo: Photo1, path: '/gravida' },
  { photo: Photo2, path: '/recem-nascido' },
  { photo: Photo3, path: '/casamento' },
  { photo: Photo4, path: '/recem-nascido' },
  { photo: Photo5, path: '/casamento' },
  { photo: Photo6, path: '/gravida' }
];

const Homepage = () => (
  <Layout bigHeader>
    <SEO title="Home" keywords={[...KEYWORDS]} />

    <Grid>
      <Row center='xs'>
        {IMAGES.map(({ photo, path }) => (
          <Col md={4} key={photo}>
            <HomepageItem to={`${PORTFOLIO_PATH}${path}`}>
              <LazyImage
                src={photo}
                alt='Homepage Photo'
                style={{
                  borderRadius: 6,
                  margin: 0,
                  marginBottom: 15
                }}
                placeholder={({ imageProps, ref }) => (
                  <div style={{ padding: '40px 0' }} ref={ref}>
                    <Loader />
                  </div>
                )}
                actual={({ imageProps }) => <img {...imageProps} alt={imageProps.alt} />}
              />
            </HomepageItem>
          </Col>
        ))}
      </Row>
    </Grid>
  </Layout>
);

export default Homepage;
